<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn icon x-small class="ml-2" v-on="on" @click="toWiki(section)">
        <v-icon>help_outline</v-icon>
      </v-btn>
    </template>
    <span>
      Go to wiki...
      <v-icon small dark>open_in_new</v-icon>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "WikiButton",
  components: {},
  mixins: [],
  props: {
    section: { type: String, required: true },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    toWiki(section) {
      const url = `${window.location.protocol}//${window.location.host}/wiki/${section}`;
      // const url = `https://irena.acelerex.com/wiki/${section}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style></style>
